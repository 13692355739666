.nomo-news-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f6f1e5;
}

.cta-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--black);
}

.cta-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    width: 100%;
}

.cta-image {
    width: 150px;
    border-radius: 50%;
    height: auto;
    margin-right: 2rem;
    border: 4px solid #d3b77b;
}

.cta-text-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cta-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #666666;
    margin-bottom: 1.5rem;
    text-align: justify;
}

/* Updated button container to display buttons in a row with wrapping */
.cta-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.cta-button {
    display: flex;
    align-items: center;
    padding: 0.8rem 2rem;
    background-color: #d3b77b;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-bottom: 1rem;
}

.wiki-button {
    display: flex;
    align-items: center;
    padding: 0.8rem 2rem;
    background-color: #d3b77b;
    color: white;
    font-family: ZeniqFont;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.cta-button:hover {
    background-color: #e2a801;
}

.wiki-button:hover {
    background-color: #e2a801;
}

.cta-icon {
    margin-right: 10px;
    font-size: 1.2rem;
}

.flag-icon {
    margin-left: 10px;
    width: 24px;
    height: auto;
}

@media (max-width: 768px) {
    .cta-content {
        flex-direction: column;
        align-items: center;
    }

    .cta-image {
        margin-right: 0;
        margin-bottom: 1.5rem;
    }

    .cta-text-block {
        align-items: center;
        text-align: center;
    }
}
