@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.main-card {
    position: relative; /* Make the main-card a positioned element */
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: var(--light-gray);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    background-image: url("./abstract.png");
    background-size: cover;
    min-height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1000px;
}

.main-card:hover {
    /*transform: translateY(-5px);*/
}

.card-image {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.overlay-content {
    position: absolute; /* Position this container absolutely */
    top: 50%; /* Move it to the vertical center */
    left: 50%; /* Move it to the horizontal center */
    transform: translate(-50%, -50%); /* Center it exactly by offsetting */
    font-family: ZeniqFont;
    color: black; /* Text color for better visibility */
    text-align: center;
    padding: 1rem;
    /*background: rgba(0, 0, 0, 0.5); !* Optional: semi-transparent background for better text readability *!*/
    border-radius: 10px; /* Optional: rounded corners */
}

.card-content {
    display: none; /* Hide the original content container */
}


.overlay-content h2 {
    font-family: 'Montserrat', sans-serif;
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 1.8rem;
}

.overlay-content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin: 0;
    color: #333;
}

@media screen and (max-width: 800px) {
    .main-card {
        min-width: 90vw;
    }


    .overlay-content h2 {
        font-size: 16px;
    }

    .overlay-content p {
        font-size: 8px;
    }

}