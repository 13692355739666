@font-face {
    font-family: 'ZeniqFont';
    src: url('../fonts/zeniq_font.otf') format('woff2'),
    url('../fonts/zeniq_font.otf') format('woff'),
    url('../fonts/zeniq_font.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    background-color: white;
    height: 2.5rem;
    margin-bottom: 2rem;
    /*border-bottom: 1px solid rgb(229, 231, 235);*/

}
.live {
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
    align-items: center;
}

.live-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #e2a801;
}

.blinking {
    height: 8px;
    width: 8px;
    background-color: #e2a801;
    border-radius: 50%;
    margin-right: 2px;
    animation: fadeBlink 1600ms infinite;
}

@keyframes fadeBlink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

/* Responsive Navbar */
@media (max-width: 768px) {
    .navbar {
        padding-left: 1rem;
        height: 2rem;
    }
}

.logo {
    color: white;
    color: black;
    font-family: 'ZeniqFont', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    border-left: solid 6px #e2a801;
    padding-left: 0.2rem;
}



/* Responsive Logo */
@media (max-width: 768px) {
    .logo {
        font-size: 0.8rem;
        border-left: solid 6px #e2a801;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.yellow-bar {
    background-color: #e2a801;
    width: 600px;
    height: 0.2rem;
    /*margin-bottom: 2rem;*/
}

/* Responsive Yellow Bar */
@media (max-width: 768px) {
    .yellow-bar {
        width: 80%; /* Set to a percentage for flexibility */
    }
}

.gray-bar {
    background-color: #d9d9d9;
    width: 100%;
    height: 0.2rem;
    /*margin-bottom: 2rem;*/
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
    .navbar {
        padding-left: 0.5rem;
        height: 1.8rem;
    }

    .logo {
        font-size: 0.7rem;
    }

    .yellow-bar {
        width: 70%;
    }
}
