/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'ZeniqFont';
  src: url('./components/fonts/zeniq_font.otf') format('woff2'),
  url('./components/fonts/zeniq_font.otf') format('woff'),
  url('./components/fonts/zeniq_font.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Root variables */
:root {
  --white: #FFFFFF;
  --light-gray: #f9f9f9;
  --black: #000000;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  /*background-color: var(--white);*/
  background-color: #faf9f8;
  color: var(--black);
  margin: 0;
  padding: 0;
}

/* Container styles */
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-col {
  display: flex;
}

/* Header styles */
.header {
  text-align: center;
}

.header h1 {
  font-family: 'Montserrat', sans-serif;
  /* font-family: ZeniqFont; */
  font-size: 3rem;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
}

.section-title {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.section-text {
  margin-bottom: 1.5rem;
  color: #666666;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  text-align: justify;
  border-left: solid 6px #cbcbcb;
  padding-left: 1rem;
}

.section-text no-border{
  border-left: none !important;
}

.section-text-yellow {
  margin-bottom: 1.5rem;
  color: #666666;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  text-align: justify;
  border-left: solid 6px #e1a702;
  padding-left: 1rem;
}

.image-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.industry-image {
  border-radius: 10px;
  height: 150px;
  width: 250px;
  object-fit: cover;
}



.header p {
  font-size: 1.2rem;
  color: #666;
}

/* Main styles */
.main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  align-items: center;
}

/* Text styles */
.text-1,
.text-2 {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.text-1 {
  color: #666666FF;
}

.text-2 {
  color: #666666FF;
  text-align: justify;
  /* max-width: 800px; */
  max-width: 1000px;
  border-left: solid 6px #e2a801;
  padding-left: 1rem;
}

/* Media queries */

/* Medium screens (max-width: 768px) */
@media (max-width: 768px) {

  /* Container adjustments */
  .container {
    padding: 1rem 1rem 0 1rem;
  }

  /* Header adjustments */
  .header h1 {
    font-size: 2.5rem;
  }

  .header p {
    font-size: 1rem;
  }

  /* Main adjustments */
  .main {
    gap: 1rem;
  }

  /* Text adjustments */
  .text-1,
  .text-2,
  .section-text {
    font-size: 14px;

  }

}

/* Small screens (max-width: 480px) */
/*@media (max-width: 480px) {*/

/*  !* Container adjustments *!*/
/*  .container {*/
/*    padding: 0.5rem 0.5rem 0 0.5rem;*/
/*  }*/

/*  !* Header adjustments *!*/
/*  .header h1 {*/
/*    font-size: 2rem;*/
/*  }*/

/*  .header p {*/
/*    font-size: 0.9rem;*/
/*  }*/

/*  !* Main adjustments *!*/
/*  .main {*/
/*    gap: 0.5rem;*/
/*  }*/

/*  !* Text adjustments *!*/
/*  .text-1,*/
/*  .text-2 {*/
/*    font-size: 11px;*/
/*    min-width: 90vw;*/
/*  }*/

/*}*/
