.block-info-bar {
    width: 100%;
    overflow: hidden;
    /*background-color: #f9f9f9;*/
    background-color: white;
    min-height: 50px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    border-bottom: 1px solid rgb(229, 231, 235);
}

.block-info-track {
    display: flex;
    animation: scroll 300s linear infinite;
    align-items: center;
    height: 1.8rem; /* Match or be less than navbar height */
}

.block-info {
    display: flex;
    align-items: center;
    /*background-color: #f9f9f9;*/
    background-color: #fff;
    margin-right: 1rem;
    padding: 0 0.5rem;
    height: 100%;
    white-space: nowrap;
    /*border-radius: 5px;*/
    border-left: solid 4px #f0f0f0;
}

.block-info span {
    margin-right: 0.5rem;
    font-size: 12px;
    color: #666;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
