@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'ZeniqFont';
    src: url('../fonts/zeniq_font.otf') format('woff2'),
    url('../fonts/zeniq_font.otf') format('woff'),
    url('../fonts/zeniq_font.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.project-card {
    /*background: linear-gradient(to top left, white, var(--light-gray), #f1f1f1);*/
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    height: 250px;
    border: 1px solid var(--light-gray);
}

.project-card a {
    text-decoration: none;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-image {
    width: 70%;
    max-width: 100px;
    height: 80px;
    object-fit: contain;
    padding: 0 1rem 1rem 0rem;
}

.project-content {
    padding: 1.5rem;
    text-align: justify;
}

.project-content h2 {
    font-family: 'ZeniqFont';
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    text-wrap: wrap;
    color: black;
}

.project-content p {
    margin: 0;
    color: #666666;
    font-size: 14px;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
}