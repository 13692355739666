@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'ZeniqFont';
    src: url('../fonts/zeniq_font.otf') format('woff2'),
    url('../fonts/zeniq_font.otf') format('woff'),
    url('../fonts/zeniq_font.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.company-card {
    /*background-color: var(--light-gray);*/
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    height: 250px;
}

.company-card a {
    text-decoration: none;
}

.company-card:hover {
    transform: translateY(-5px);
}

.company-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
}

.company-image {
    width: 300px;
    max-width: 300px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.company-content p {
    margin: 0;
    color: #666666;
    font-size: 14px;
    text-align: justify;
}
